var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"page",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
              },attrs:{"id":"input-1","placeholder":"Enter Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"input-group-2","label-for":"input-2"}},[_c('label',[_vm._v("Subject "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.subject.$error,
              },attrs:{"id":"input-2","placeholder":"Enter Subject"},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}}),(_vm.submitted && !_vm.$v.form.subject.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Subject is required. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"input-group-3","label-for":"input-3"}},[_c('label',[_vm._v("Content "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-textarea',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.content.$error,
                },attrs:{"id":"input-3","rows":"3","max-rows":"6","placeholder":"Enter Content"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),(_vm.submitted && !_vm.$v.form.content.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Content is required. ")]):_vm._e()],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-page-notification')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }